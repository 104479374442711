@import styles/_variables

.input,
.textarea
  background-color: #f9f9f9
  border: 1px solid #999
  border-radius: 8px
  font-family: $font-primary
  font-size: 1.1em
  outline: 0
  padding: 8px 16px
  width: 100%

  &:focus
    border-color: $fc-color-link-hover

.inputError,
.textareaError
  border-color: #ff4d4f

  &:focus
    border-color: #ff4d4f

.inputDisabled,
.textareaDisabled
  background-color: #f0f0f0
  border-color: #ccc
