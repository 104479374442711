.modal
  display: flex
  flex-direction: column

.modalContent
  overflow: auto
  padding: 20px

.modal :global(.modal-header)
  flex-grow: 0
  flex-shrink: 0
