@import styles/_variables

.wrapper
  align-items: stretch
  background-color: $fc-color-dark-brown
  display: flex
  flex-direction: row
  justify-content: space-between
  min-height: 100px
  overflow: hidden

  &:hover
    background-color: lighten($fc-color-dark-brown, 3%)

.imageWrapper
  align-items: center
  display: flex
  flex-grow: 0
  flex-shrink: 0
  min-height: 100px
  justify-content: center
  overflow: hidden
  position: relative
  width: 33%

.text
  align-items: center
  color: #fff
  display: flex
  flex-grow: 1
  flex-shrink: 1
  font-size: 1.1em
  font-weight: 500
  justify-content: flex-start
  line-height: 1.4
  margin: 0
  padding-left: 1.5em
  padding-right: 1.5em
  text-decoration: none

@media all and (min-width: map-get($grid-breakpoints, "md"))
  .text
    padding-left: 2em
    padding-right: 2em


.icon
  align-items: center
  display: flex
  flex-grow: 0
  flex-shrink: 0
  justify-content: flex-start
  padding-right: 1.5em

  & svg
    color: rgba(#fff, 1)
    transform: rotateZ(90deg)
    transition: transform .25s ease, color .25s ease

@media all and (min-width: map-get($grid-breakpoints, "sm"))
  .icon
    padding-right: 2em


.wrapperCollapsed
  .icon
    & svg
      color: rgba(#fff, .75)
      transform: rotateZ(0deg)

// Color adjustments

.wrapperBlue
  background-color: $fc-color-violet-blue

  &:hover
    background-color: lighten($fc-color-violet-blue, 3%)

.wrapperGreen
  background-color: $fc-color-soft-green

  &:hover
    background-color: lighten($fc-color-soft-green, 3%)

.wrapperGray
  background-color: $fc-color-brownie-gray

  &:hover
    background-color: darken($fc-color-brownie-gray, 5%)

  .text
    color: $fc-color-link-hover

  .icon
    & svg
      color: rgba($fc-color-link-hover, 1)

  .wrapperCollapsed
    .icon
      & svg
        color: rgba($fc-color-link-hover, .75)
