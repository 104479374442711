.card
  background-color: #fff
  border-radius: 8px
  box-shadow: 1px 2px 3px rgba(#000, .15)
  overflow: hidden
  color: #323232

.cardPadding
  padding: 10px

.cardLink
  &:hover
    box-shadow: 1px 3px 10px rgba(#000, .15)
    text-decoration: none
