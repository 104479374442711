@import styles/_variables

.ellipsis
  display: inline-block
  height: 40px
  position: relative
  width: 80px

  div
    animation-timing-function: cubic-bezier(0, 1, 1, 0)
    background: $fc-color-soft-green
    border-radius: 50%
    height: 13px
    position: absolute
    top: 13px
    width: 13px

  div:nth-child(1)
    animation: ellipsis1 0.6s infinite
    left: 8px

  div:nth-child(2)
    animation: ellipsis2 0.6s infinite
    left: 8px

  div:nth-child(3)
    animation: ellipsis2 0.6s infinite
    left: 32px

  div:nth-child(4)
    animation: ellipsis3 0.6s infinite
    left: 56px

@keyframes ellipsis1
  0%
    transform: scale(0)
  100%
    transform: scale(1)

@keyframes ellipsis3
  0%
    transform: scale(1)
  100%
    transform: scale(0)

@keyframes ellipsis2
  0%
    transform: translate(0, 0)
  100%
    transform: translate(24px, 0)

