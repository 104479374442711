.header
  align-items: center
  border-bottom: 1px solid #f0f0f0
  display: flex
  flex-direction: row
  flex-grow: 0
  flex-shrink: 0
  justify-content: space-between
  padding: 8px 10px 8px 20px

.title
  color: #999
  font-size: 1.25em

.button
  align-items: center
  background-color: transparent
  border: none
  display: flex
  justify-content: center
  padding: 6px 10px
