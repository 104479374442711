.wrapper
  background-color: #fff
  border-radius: 8px
  box-shadow: 1px 2px 3px rgba(#000, .15)
  display: block
  margin-bottom: 2em
  overflow: hidden
  position: relative

.content
  display: block
  position: relative
  padding: 1em

@media all and (min-width: 768px)
  .wrapper
    margin-bottom: 1em
