@import styles/_variables

.wrapper
  display: block

.wrapper
  margin-bottom: .75em
  margin-top: .75em
  text-align: left

  & > label
    align-items: flex-start
    display: flex
    flex-direction: column-reverse

    & > span
      color: #666
      display: block
      font-size: .875em
      line-height: 1
      margin-bottom: .25em
      transform: translate(16px, 2.1em)
      transition: .1s ease-in-out

    & input
      width: 100%

    & input:focus + span
      color: $fc-color-link-hover
      transform: translate(0, 0)

.wrapperWithValue
  & > label
    & > span
      transform: translate(0, 0)

.wrapperDisabled
  & > label
    & > span
      color: #aaa

.error
  color: #ff4d4f
  font-size: .875em
  line-height: 1
  margin: .25em 16px 0 16px
  text-align: right
