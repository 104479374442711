.figure
  border-left: 8px solid #ddd
  border-radius: 8px
  background: #eee
  margin: 1.5em 0
  padding: 1em

  blockquote
    font: 1.05rem/1.5 Georgia, serif
    margin: 0

  figcaption
    margin-top: .5rem
    font-size: .75rem
