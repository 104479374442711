@import styles/_variables

.cardImage
  overflow: hidden
  position: relative

.first
  border-top-left-radius: 8px
  border-top-right-radius: 8px

.last
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px

.imageWrapper
  align-items: center
  display: flex
  justify-content: center
  overflow: hidden
  position: relative

.label
  align-items: center
  background-color: #ccc
  display: flex
  color: #fff
  font-family: $font-secondary
  font-size: 1.25em
  justify-content: center
  letter-spacing: 1px
  padding: 4px 8px
  text-transform: uppercase

.labelBrown
  background-color: $fc-color-dark-brown

.labelBlue
  background-color: $fc-color-violet-blue

.labelGreen
  background-color: $fc-color-soft-green

:global(.card-link)
  &:hover > .cardImage
    .label
      background-color: darken(#ccc, 10%)

    .labelBrown
      background-color: darken($fc-color-dark-brown, 10%)

    .labelBlue
      background-color: darken($fc-color-violet-blue, 10%)

    .labelGreen
      background-color: darken($fc-color-soft-green, 10%)
