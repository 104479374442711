@import styles/_variables

.fc-icon
  display: inline-block
  background-image: url('~assets/images/sprite-icons.png')
  background-position: 0 0
  background-repeat: no-repeat

  &.fc-icon-dark-brown
    background-color: $fc-color-dark-brown

  &.fc-icon-small
    width: 25px
    height: 25px
    border-top-left-radius: 12px
    border-top-right-radius: 12px
    border-bottom-left-radius: 12px
    border-bottom-right-radius: 12px

  &.fc-icon-plus
    background-position: 1px -258px



// .icon-small.plus.dark-brown
//   background-color:#b8a295

// .icon-small.plus.dark-brown:hover
//   background-color:#c2afa4
