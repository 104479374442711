.section
  align-items: center
  display: flex
  height: 100vh
  justify-content: center

.cardContent
  padding: 32px
  text-align: center

.logoContainer
  margin-bottom: 32px
