@import styles/_variables

.wrapper
  margin-bottom: .75em
  margin-top: .5em

.label
  align-items: center
  display: flex
  justify-content: flex-start
  text-align: left

  &:hover
    cursor: pointer

  & input
    display: none

  & svg
    color: darken($fc-color-dark-brown, 5%)
    font-size: 1.25em
    flex-shrink: 0
    margin-right: .5em

.wrapperDisabled
  .label
    color: #aaa

    &:hover
      cursor: default

    & svg
      color: #bbb

.error
  color: #ff4d4f
  font-size: .875em
  line-height: 1
  margin: .25em 16px 0 32px
  text-align: left
