@import styles/_variables

.ul
  list-style: none
  margin: 1em 0
  padding-left: 3em
  position: relative

  li
    line-height: 1.5
    margin: .75em 0
    position: relative

  :global(.li-icon)
    color: #333
    font-size: 2em
    left: -1.25em
    position: absolute
    top: -.1em

.ulQuestion
  :global(.li-icon)
    color: $fc-color-dark-brown
    font-size: 1.1em
    left: -1.85em
    top: .15em

.ulCheck
  :global(.li-icon)
    color: $fc-color-soft-green
    font-size: 1.2em
    left: -1.75em
    top: .12em
