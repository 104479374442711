@import bootstrap/scss/functions

// page layout
$wrapper-width: 960px
$page-width: 940px

// general and reusable colors
$fc-color-violet-blue: #a0afd9
$fc-color-light-salmon: #dbb7aa
$fc-color-brownie-gray: #e1d9d6
$fc-color-soft-green: #8cd0aa
$fc-color-dark-brown: #ae9384

$fc-color-link: darken($fc-color-dark-brown, 10%)
$fc-color-link-hover: #825c5c // the same as the logo dark color

// fonts
$font-primary: "open-sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
$font-secondary: "proxima-nova-extra-condensed", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

// bootstrap
@import bootstrap/scss/variables
@import bootstrap/scss/mixins
