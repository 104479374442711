.alert
  border-left: 16px solid #ebebeb
  border-radius: 8px
  box-shadow: 0 0 10px 3px rgba(#000, .20)
  margin: 20px auto
  padding: 16px 32px
  position: relative
  text-align: left
  width: 100%

.alertError
  border-left: 16px solid #f7a7a3
  color: darken(#f7a7a3, 50)

.alertSuccess
  border-left: 16px solid #a8f0c6
  color: darken(#a8f0c6, 50)

.alertWarning
  border-left: 16px solid #ffd48a
  color: darken(#ffd48a, 50)
