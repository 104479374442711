@import styles/_variables

.button
  background-color: $fc-color-dark-brown
  border: 1px solid darken($fc-color-dark-brown, 5%)
  border-radius: 8px
  display: inline-block
  color: #f0f0f0
  font-family: $font-primary
  font-size: 1rem
  font-weight: 400
  inset: 0
  letter-spacing: .01em
  line-height: 1.25em
  margin: .5em 0
  outline: none
  padding: .5em 1.5em
  position: relative
  text-align: center
  text-decoration: none
  text-transform: uppercase
  text-shadow: 1px 1px 0 rgba(#000, .25)
  width: auto

  &:hover
    background-color: lighten($fc-color-dark-brown, 10%)
    color: #fff
    cursor: pointer

  &.buttonDisabled,
  &.buttonDisabled:hover
    border-color: lighten($fc-color-dark-brown, 10%)
    background-color: lighten($fc-color-dark-brown, 10%)
    color: rgba(#f0f0f0, .5)
    cursor: default
    text-shadow: none

.buttonBlue
  background-color: $fc-color-violet-blue
  border-color: darken($fc-color-violet-blue, 5%)
  color: #fff

  &:hover
    background-color: darken($fc-color-violet-blue, 10%)

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: lighten($fc-color-violet-blue, 5%)
    border-color: lighten($fc-color-violet-blue, 5%)
    color: #f0f0f0

.buttonGreen
  background-color: $fc-color-soft-green
  border-color: darken($fc-color-soft-green, 5%)
  color: #fff

  &:hover
    background-color: darken($fc-color-soft-green, 10%)

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: lighten($fc-color-soft-green, 5%)
    border-color: lighten($fc-color-soft-green, 5%)
    color: #f0f0f0

.buttonSalomon
  background-color: $fc-color-light-salmon
  border-color: darken($fc-color-light-salmon, 5%)
  color: #fff

  &:hover
    background-color: darken($fc-color-light-salmon, 10%)

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: lighten($fc-color-light-salmon, 5%)
    border-color: lighten($fc-color-light-salmon, 5%)
    color: #f0f0f0

.buttonGray
  background-color: $fc-color-brownie-gray
  border-color: darken($fc-color-brownie-gray, 5%)
  color: darken($fc-color-brownie-gray, 50%)
  text-shadow: none

  &:hover
    background-color: darken($fc-color-brownie-gray, 5%)
    color: darken($fc-color-brownie-gray, 60%)

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: lighten($fc-color-brownie-gray, 5%)
    border-color: lighten($fc-color-brownie-gray, 5%)
    color: #f0f0f0

.buttonLink
  background-color: transparent
  border: none
  color: $fc-color-dark-brown
  display: inline-block
  padding: .5em
  text-shadow: none
  text-transform: none

  &:hover
    background-color: transparent
    color: darken($fc-color-dark-brown, 10%)
    text-decoration: underline

  &.buttonDisabled,
  &.buttonDisabled:hover
    color: lighten($fc-color-dark-brown, 15%)
    text-decoration: none

.buttonBlock
  width: 100%

.buttonSmall
  font-size: .875rem
  padding: .4em 1.5em

.buttonLarge
  font-size: 1.125rem
  padding: .6em 1.5em

  svg
    font-size: .9em
    margin-top: -.15em
