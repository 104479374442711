@import styles/_variables

.tags
  display: block
  list-style-type: none
  margin: 1em 0
  padding: 0
  position: relative
  max-width: 100%

  li
    display: inline-block
    line-height: 2

  a
    display: inline
    background-color: darken($fc-color-brownie-gray, 10%)
    border-radius: 5px
    padding: 5px
    margin-right: 10px
    color: #fff
    font-size: 0.875em
    text-shadow: -1px -1px 0 rgba(#000, .25)
    white-space: nowrap

    &:hover,
    &:active
      background-color: darken($fc-color-brownie-gray, 15%)
