@import styles/_variables

.link
  border-radius: 12px
  display: block
  margin-bottom: 4rem
  overflow: hidden
  padding: .5rem
  position: relative
  width: 100%

  &:hover,
  &:active
    background-color: #f0f0f0

  :global(.headline)
    border-top: 1px solid #ccc
    color: $fc-color-link-hover
    margin: .75rem .5rem
    margin-bottom: .75rem
    margin-top: .75rem
    padding-top: .75rem

  :global(.date)
    color: lighten($fc-color-link-hover, 5%)
    font-size: .875rem
    filter: saturate(.75)
    margin: 0 .5rem

  :global(.teaser)
    color: $fc-color-link-hover
    display: -webkit-box
    filter: saturate(.6)
    line-height: 1.3
    margin: 0 .5rem .75rem .5rem
    max-height: 5rem
    overflow: hidden
    text-overflow: ellipsis
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical

@media all and (min-width: map-get($grid-breakpoints, "lg"))
  .link
    display: flex
    justify-content: stretch
    margin-bottom: 2rem

    article
      align-items: stretch
      display: flex
      flex-direction: column
      justify-content: space-between
      max-width: 100%

    :global(.headline),
    :global(.date),
    .imageContainer
      flex-grow: 0
      flex-shrink: 0

    :global(.teaser)
      flex-grow: 1
      flex-shrink: 1

.imageContainer
  align-items: center
  border-radius: 8px
  display: flex
  height: 250px
  justify-content: center
  overflow: hidden
  position: relative

