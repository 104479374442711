.cardContent
  overflow: hidden
  padding: 8px 16px

.first
  border-top-left-radius: 8px
  border-top-right-radius: 8px

.last
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
